export const DefaultAdultsActivities = [
    {
        "id": "0",
        "titre": "Carte vide",
        "lieu": null,
        "debut": "00:00",
        "duree": null,
        "type": false,
        "description": "Carte vide",
        "category": "#FFFFFF00"
    },
    {
        "id": "48",
        "titre": "Tournoi de fléchette",
        "traduction": "",
        "lieu": "",
        "debut": "15:00",
        "duree": "",
        "type": true,
        "description": "",
        "icon": "./static/media/Fichier 53.899a70e9eb815466dfe5.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "92",
        "titre": "Tournoi de billard",
        "traduction": null,
        "lieu": null,
        "debut": "15:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 113.7d55ad1f61a13a77659a.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "140",
        "titre": "Tournoi de baby-foot",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 112.19ce22d10194d27ad589.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "162",
        "titre": "Molky",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 82.ef8b11db5875c9634803.svg",
        "category": "#FFFFFF00"
    },
    
    {
        "id": "200",
        "titre": "Belote",
        "traduction": null,
        "lieu": null,
        "debut": "17:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 84.add34d27c417f889ec6d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "222",
        "titre": "Tarot",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 84.add34d27c417f889ec6d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "338",
        "titre": "Tournoi de football",
        "traduction": null,
        "lieu": null,
        "debut": "16:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 97.f8a07c40b1a2ee4c081a.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "504",
        "titre": "Beach Volley",
        "traduction": null,
        "lieu": null,
        "debut": "16:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 59.890d1c343112aa6bb815.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "538",
        "titre": "Volley ball",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 120.8637151d10bf860705b7.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "590",
        "titre": "Tournoi de basket-ball",
        "traduction": null,
        "lieu": null,
        "debut": "14:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 79.a389aeb1b620f28bfc6b.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "628",
        "titre": "Tennis de table",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 49.a5f9abe531e26a3b72dc.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "658",
        "titre": "Tournoi de pétanque",
        "traduction": null,
        "lieu": null,
        "debut": "11:30",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 86.a967fb62dc458ada733a.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "690",
        "titre": "Water polo",
        "traduction": null,
        "lieu": null,
        "debut": "16:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 59.890d1c343112aa6bb815.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "726",
        "titre": "Water volley",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 62.03e179534d49bf5c9c40.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "762",
        "titre": "Water basket",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 114.86d772df4cca0863fea2.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "784",
        "titre": "Running",
        "traduction": null,
        "lieu": null,
        "debut": "08:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 52.b126d970472167cdc6b1.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "810",
        "titre": "Badminton",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 115.1a5102aeb27072d94355.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "832",
        "titre": "Aquagym",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 111.0bd924bab74343e88bf4.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "890",
        "titre": "Aqua'fun",
        "traduction": null,
        "lieu": null,
        "debut": "09:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 40.d4748d1944efc21f1826.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "902",
        "titre": "Zumb'Aqua",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 35.f5280c58bd7b0e8ead82.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "912",
        "titre": "Aqua'Bike",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 121.94f0afad89bab59492e6.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "978",
        "titre": "Bébé nageurs",
        "traduction": null,
        "lieu": null,
        "debut": "11:30",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 19.449a7a4cd2048e03ffa1.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "994",
        "titre": "Step",
        "traduction": null,
        "lieu": null,
        "debut": "09:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 102.f1c5e84f5e147706f84d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1046",
        "titre": "Cuisses abdos fessiers",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 118.da9e6e935e8348a94845.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1062",
        "titre": "Yoga",
        "traduction": null,
        "lieu": null,
        "debut": "11:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 63.fcd803268a89e6ee394b.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1090",
        "titre": "Stretching",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 102.f1c5e84f5e147706f84d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1104",
        "titre": "Relaxation",
        "traduction": null,
        "lieu": null,
        "debut": "18:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 75.b359de9ab6178f4c0426.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1116",
        "titre": "Reveil musculaire",
        "traduction": null,
        "lieu": null,
        "debut": "08:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 118.da9e6e935e8348a94845.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1146",
        "titre": "Gym douce",
        "traduction": null,
        "lieu": null,
        "debut": "09:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 76.09a4c28ed7a0e9afc55d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1164",
        "titre": "Zumba",
        "traduction": null,
        "lieu": null,
        "debut": "10:30",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 102.f1c5e84f5e147706f84d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1206",
        "titre": "Circuit training",
        "traduction": null,
        "lieu": null,
        "debut": "09:30",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 118.da9e6e935e8348a94845.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1244",
        "titre": "Balade pédestre",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 61.02b73ae8b44e6a27c85e.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1294",
        "titre": "Balade gastronomique",
        "traduction": null,
        "lieu": null,
        "debut": "12:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 26.cc6e2537c3ad1eaafc57.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1324",
        "titre": "Balade vélo",
        "traduction": null,
        "lieu": null,
        "debut": "15:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 58.7e4c484636166417ee50.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1366",
        "titre": "Cours de cuisine",
        "traduction": null,
        "lieu": null,
        "debut": "11:30",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 26.cc6e2537c3ad1eaafc57.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1406",
        "titre": "Cours de danse",
        "traduction": null,
        "lieu": null,
        "debut": "18:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 102.f1c5e84f5e147706f84d.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1454",
        "titre": "Cours de cocktails",
        "traduction": null,
        "lieu": null,
        "debut": "18:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 34.fbb4bbe04858be1509fc.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1622",
        "titre": "Forum d'accueil",
        "traduction": null,
        "lieu": null,
        "debut": "10:00",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 37.26ef21a1b2218268331f.svg",
        "category": "#FFFFFF00"
    },
    {
        "id": "1668",
        "titre": "Do you play",
        "traduction": null,
        "lieu": null,
        "debut": "18:30",
        "duree": null,
        "type": true,
        "description": null,
        "icon": "./static/media/Fichier 77.dd29a67f262ff6989c3f.svg",
        "category": "#FFFFFF00"
    }


]
export const DefaultKidsActivities = [
    {
        "id": "0",
        "titre": "Carte vide",
        "lieu": null,
        "debut": "00:00",
        "duree": null,
        "type": false,
        "description": "Carte vide",
        "category": "#FFFFFF00"
    }
]