import { React, useEffect, useState, useContext } from "react";
import PlanningConceptorLines from "./planning_conceptor_lines/planning_conceptor_lines";
import "./planning_conceptor.scss";

/* Import traductions */
import { daysFrEn, daysFrEs, daysFrIt } from "../../assets/data/traductions";
import { PlanningContext } from "../../App";



const PlanningConceptor = (props) => {

    const [niceTrad, setNiceTrad] = useState([])
    const
        {
            traduction,
            savePlanning,
        } = useContext(PlanningContext);


    useEffect(() => {
        switch (traduction) {
            case "Anglais":
                setNiceTrad(daysFrEn)
                break;
            case "Espagnol":
                setNiceTrad(daysFrEs)
                break;
            case "Italien":
                setNiceTrad(daysFrIt)
                break;
            default:
                break;
        }
        return () => {
        };
    }, [niceTrad]);

    return (
        <table cellSpacing={0} cellPadding={0}>
            <thead>
                <tr>
                    <th></th>
                    {[...Array(props.columns)].map((e, i) => (
                        <th key={i} className="long">
                            <div className="long-bloc-text">
                                <span className="long-text">{props.daysInStr[i]}</span>
                                <span className="trad-text">
                                    {niceTrad[props.daysInStr[i]]}
                                </span>
                            </div>
                        </th>)
                    )}
                </tr>
            </thead>
            <tbody>

                {[...Array(props.lines)].map((e, i) => <PlanningConceptorLines activitiesInLine={savePlanning[i]} traduction={niceTrad} key={i} row={i} lines={props.lines} linesInStr={props.linesInStr[i]} columns={props.columns} />)}
            </tbody>
        </table>
    )
}

export default PlanningConceptor;