import { React, useState, useEffect, useContext } from "react";
import './single_activity.scss';
import { useDrag } from "react-dnd";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import { Switch } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import defaultIcon from '../../../assets/img/icons/Fichier 10.svg';
import { DefaultEditor } from 'react-simple-wysiwyg';


import { PlanningContext } from "../../../App"


const SingleActivity = (props) => {

    const
    {
        camping,
        traduction,
        days,
        savePlanning, setSavePlanning,
        adultsActivities, setAdultsActivities,
        kidsActivities, setKidsActivities,
        planningType,
        lines
    } = useContext(PlanningContext);

    const [id, setId] = useState(props.data.id);
    const [titre, setTitre] = useState(props.data.titre);
    const [trad, setTrad] = useState(props.data.trad);
    const [icon, setIcon] = useState(props.data.icon);
    const [lieu, setLieu] = useState(props.data.lieu);
    const [debut, setDebut] = useState(props.data.debut);
    const [duree, setDuree] = useState(props.data.duree);
    const [categoryValue, setCategoryValue] = useState(props.data.category);
    const [type, setType] = useState("activity");
    const [isActivity, setIsActivity] = useState(props.data.type)
    const [description, setDescription] = useState(props.data.description)
    const [backgroundEmptyCard, setBackgroundEmptyCard] = useState('#ffffff')
    const [colorEmptyCard, setColorEmptyCard] = useState('#000000')
    const [fontSize, setFontSize] = useState(14);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const orderInplanning = () => {

        function compare(a, b) {
            let time1;
            let time2;
            if (a.debut && b.debut) {
                time1 = parseFloat(a.debut.replace(':', '.').replace(/[^\d.-]/g, ''));
                time2 = parseFloat(b.debut.replace(':', '.').replace(/[^\d.-]/g, ''));
            }
            if (time1 < time2) return -1;
            if (time1 > time2) return 1;
            return 0;
        }
        let test = props.inPlanning.sort(compare)
        props.setInplanning([...test])

    }
    useEffect(() => {
        if (props.type) {
            setType(props.type);
        }

    }, [props.data.type, orderInplanning, open])

    const changeRdv = (e) => {
        setLieu(e.target.value);
    }

    const changeHeure = (e) => {
        setDebut(e.target.value);
        let temp = [...props.inPlanning]
        let future = [];
        temp.map((element) => {
            element.id === id ? (element = { ...element, ...{ debut: e.target.value } }) : (element = element);
            future.push(element);

        })
        props.setInplanning(future);
    }
    const changeFontSize = (e) => {
        setFontSize(e.target.value)
    }
    const changeNom = (e) => {
        setTitre(e.target.value);
    }
    const changeDescription = (e) => {
        setDescription(e.target.value);
    }
    const changeDuree = (e) => {
        setDuree(e.target.value);
    }

    const ChangeEmptyCardType = (e) => {
        let currentColor = e.target.value
        setBackgroundEmptyCard(currentColor)
        let colorsRelative = {
            '#ffffff': '#000000',
            '#AB9967': '#ffffff',
            '#ab9967': '#ffffff'
        }
        setColorEmptyCard(colorsRelative[currentColor])
    }

    const deleteActivity = (e) => {
        let id = e.target.attributes.idelement.value
        let nextPlanning = []
        props.inPlanning.forEach((el) => {
            if (el.id === id) {
                return
            }
            nextPlanning.push(el)
        })
        props.setInplanning([...nextPlanning])
        handleClose();
    }

    const [{ isDragging }, dragRef] = useDrag({
        type: type,
        item: { id: id, titre: titre, trad: trad, lieu: lieu, debut: debut, duree: duree, type: isActivity, description: description, icon: icon, category: categoryValue },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: type === 'activity' ? true : false
    })

    return (
        <>
            {isActivity ? (
                <>
                    <Modal open={open} onClose={handleClose}>
                        <Box className='ModalBox' component="form">
                            <div className="box-content-modify">
                                <h3 className="create-activity-title">Modifier une animation</h3>
                                {type === 'activityIn' ? (
                                    <div className="col1">
                                        <TextField id="rdvInput" className="singleInput" label="Lieu de l'animation" onChange={changeRdv} variant="outlined" type='text' />
                                        <TextField id="heureInput" className="singleInput" label="" variant="outlined" onChange={changeHeure} type='time' value={debut} />
                                        <TextField id="dureeInput" className="singleInput" label="Durée" variant="outlined" onChange={changeDuree} type='number' value={duree ? duree : ''} InputProps={{ endAdornment: <InputAdornment position="end">min</InputAdornment> }} />
                                    </div>
                                ) :
                                    (
                                        <div className="col1">

                                            <TextField id="rdvInput" className="singleInput" label="Nom de l'animation" onChange={changeNom} variant="outlined" type='text' value={titre} />
                                            <TextField id="rdvInput" className="singleInput" label="Lieu de l'animation" onChange={changeRdv} variant="outlined" type='text' value={lieu} />
                                            <FormControl fullWidth>
                                                <InputLabel id="select-category">Catégorie</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={categoryValue}
                                                    label="Type de carte"
                                                    onChange={(e) => setCategoryValue(e.target.value)}
                                                >
                                                    <MenuItem value={'#e34b36'}>Activités sportives</MenuItem>
                                                    <MenuItem value={'#ec60c5'}>Activités ludiques</MenuItem>
                                                    <MenuItem value={'#009ddd'}>Activités aquatiques</MenuItem>
                                                    <MenuItem value={'#18a975'}>Activités découvertes</MenuItem>
                                                    <MenuItem value={'#f47d21'}>Activités fitness</MenuItem>
                                                    <MenuItem value={'#9747ff'}>Evènementiel</MenuItem>
                                                    <MenuItem value={'#FFFFFF00'}>Autre</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )}

                                <div className="btn-group">
                                    <ButtonUnstyled className="delete-btn" onClick={deleteActivity} idelement={id}>Supprimer</ButtonUnstyled>
                                    <ButtonUnstyled className="validation-btn" onClick={handleClose}>Valider</ButtonUnstyled>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <div className={`singleActivity activity-${id}`} id={id} ref={dragRef} onClick={handleOpen}>
                        <div className="activity-time">
                            <div className="activity-head-one">
                                <span className="activity-time-debut">{debut}</span>
                                {duree ? (<span className="activity-time-duree">{duree}"</span>) : (<></>)}
                            </div>



                            {icon ? (
                                <div className="activity-head-two" style={{ background: categoryValue }}>
                                    <img className="iconActivity" src={icon ? icon : ''} alt="Icône activité" style={{ filter: categoryValue !== "#FFFFFF00" ? "invert(1)" : 'none' }} />
                                </div>
                            ) : (<></>)}

                        </div>
                        <div className="activity-title">
                            <h3>{titre}</h3>
                        </div>
                        <div className="activity-location">
                            {lieu}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {/* Carte Libre */}
                    <Modal open={open} keepMounted onClose={handleClose}>
                        <Box className='ModalBox' component="form">
                            <div className="box-content-modify">
                                <h3 className="create-activity-title">Modifier une carte libre</h3>
                                <div className="col1">
                                    <TextField id="heureInput" className="singleInput" label="Position horaire" variant="outlined" onChange={changeHeure} type='time' value={debut} />
                                    <div style={{ border: "1px solid #8080807a", borderRadius: "4px", padding: "5px", maxWidth: "95%" }}>
                                        <DefaultEditor value={description} onChange={changeDescription} />
                                    </div>
                                    <TextField
                                        id="fontSizeInput"
                                        className="singleInput"
                                        label="Taille du texte"
                                        variant="outlined"
                                        type='number'
                                        onChange={changeFontSize}
                                        value={fontSize}
                                        InputProps={{
                                            inputProps: {
                                                max: 30, min: 10
                                            }
                                        }}
                                    />
                                    {props.row !== 2 ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="select-background">Type de carte</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={backgroundEmptyCard}
                                                label="Type de carte"
                                                onChange={ChangeEmptyCardType}
                                            >
                                                <MenuItem value={'#ffffff'}>Fond blanc</MenuItem>
                                                <MenuItem value={'#AB9967'}>Fond Doré</MenuItem>
                                            </Select>
                                        </FormControl>) : (<></>)}

                                </div>
                                <div className="btn-group">
                                    <ButtonUnstyled className="delete-btn" onClick={deleteActivity} idelement={id}>Supprimer</ButtonUnstyled>
                                    <ButtonUnstyled className="validation-btn" onClick={handleClose}>Valider</ButtonUnstyled>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <div style={backgroundEmptyCard !== '#ffffff' ? { backgroundColor: backgroundEmptyCard, backgroundImage: 'unset' } : { backgroundColor: backgroundEmptyCard }} className={`emptyCard activity-${id}`} ref={dragRef} onClick={type === 'activityIn' ? handleOpen : handleClose}>
                        <div className="emptyCard-title">
                            <span style={{ color: colorEmptyCard, fontSize: `${fontSize}px` }} dangerouslySetInnerHTML={{ __html: description }}></span>

                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}
export default SingleActivity;
