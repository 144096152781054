import './wysiwyg.scss';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { React, useState, useContext } from "react";
import iconInfo from '../../assets/img/information-button.svg';
import { PlanningContext } from "../../App";

export function Wysiwyg(props) {

    const { planningType } = useContext(PlanningContext);
    const [html, setHtml] = useState(props.innerContent)
    const [file, setFile] = useState()
    const onChange = (e) => {
        setHtml(e.target.value);
    }
    const onChangeFile = (e) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setFile(e.target.result)
        }
    }

    const [firstBlockTitle, setFirstBlockTitle] = useState("Ne pas oublier");
    const [secondBlockTitle, setSecondBlockTitle] = useState("Ne pas oublier");
    const [thirdBlockTitle, setThirdBlockTitle] = useState("Ne pas oublier");
    const [fourthBlockTitle, setFourthBlockTitle] = useState("Ne pas oublier");
    const [firstBlockContent, setFirstBlockContent] = useState("Ne pas oublier");
    const [secondBlockContent, setSecondBlockContent] = useState("");
    const [thirdBlockContent, setThirdBlockContent] = useState("");
    const [fourthBlockContent, setFourthBlockContent] = useState("");

    return (
        planningType === "Adultes" ? (
            <div className='text-editor-part'>
                <div className='QrCode-part'>
                    <label className="custom-file-upload-2">
                        <input type="file" onChange={onChangeFile} accept=".jpg,.png,.svg,.jpeg" />
                        <span className='qrcode-span' >Ajouter un QR code</span>
                    </label>
                    {file ? (<img className='qrCode' src={file} alt='QrCode' />) : (<></>)}
                </div>
                <div className='text-editor-part-in'>
                    {/* <img src={iconInfo} className='imgInfo' width='50px' height='50px' /> */}
                    <DefaultEditor value={html} onChange={onChange} />

                </div>
            </div>
        ) : (
            <div className='text-editor-part-child'>
                <div className='text-editor-part-child-in bg-1' style={{ flex: "1 1 auto" }}>
                    <div className='childBanner'>
                        <input type="text" value={firstBlockTitle} onChange={(e) => setFirstBlockTitle(e.target.value)} />
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%"
                    }}>
                        <div className='QrCode-part'>
                            <label className="custom-file-upload-2">
                                <input type="file" onChange={onChangeFile} accept=".jpg,.png,.svg,.jpeg" />
                                <span className='qrcode-span' >Ajouter un QR code</span>
                            </label>
                            {file ? (<img className='qrCode' src={file} alt='QrCode' />) : (<></>)}
                        </div>
                        <DefaultEditor value={firstBlockContent} onChange={(e) => setFirstBlockContent(e.target.value)} />
                    </div>

                </div>
                <div className='text-editor-part-child-in bg-3' style={{ flex: "1 1.5 auto" }}>
                    <div className='childBanner'>
                        <input type="text" value={thirdBlockTitle} onChange={(e) => setThirdBlockTitle(e.target.value)} />
                    </div>
                    <DefaultEditor value={thirdBlockContent} onChange={(e) => setThirdBlockContent(e.target.value)} />
                </div>
                <div className='text-editor-part-child-in bg-4' style={{ flex: "1 1.5 auto" }}>
                    <div className='childBanner'>
                        <input type="text" value={fourthBlockTitle} onChange={(e) => setFourthBlockTitle(e.target.value)} />
                    </div>
                    <DefaultEditor value={fourthBlockContent} onChange={(e) => setFourthBlockContent(e.target.value)} />
                </div>
            </div>
        )

    )
}