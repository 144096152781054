import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useDrop } from 'react-dnd';
import SingleActivity from '../../planning_activity/single_activity/single_activity';
import _uniqueId from 'lodash/uniqueId';
import { PlanningContext } from "../../../App";
import KidActivity from '../../planning_activity/kid_activity/kid_activity';
import { Button } from '@mui/material';


export function PlanningCase(props) {

    const
    {
        camping,
        traduction,
        days,
        savePlanning, setSavePlanning,
        adultsActivities, setAdultsActivities,
        kidsActivities, setKidsActivities,
        planningType,
        lines
    } = useContext(PlanningContext);
    let row = props.row
    let col = props.i

    const [colSpan, setColSpan] = useState(1);

    const [inPlanning, setInplanning] = useState([...props.activityInThisCase])
    const uniqueId = _uniqueId()

    // Se lance au montage du component
    useEffect(() => {
        let copyAct = { ...props.activityInThisCase };
        if (Object.keys(copyAct).length > 0) {
            setInplanning(Object.values(copyAct));
        }
    }, []);


    const [{ isOver }, dropRef] = useDrop({
        accept: 'activity',
        drop: (item) => {
            if (inPlanning.length < 8) {

                if (inPlanning.some(act => act.id === item.id) && item.id != 0) {
                    
                    setInplanning([...inPlanning])
                    
                }
                else {
                    if (item.id === 0 && inPlanning.length > 0) {
                        item.id = uniqueId
                        console.log(item)
                        console.log(inPlanning)
                        item.debut = inPlanning[inPlanning.length - 1].debut
                    }
                    console.log('item', item)
                  
                    setInplanning([...inPlanning, item])
                    
                    
                }
            }
            else {
                alert('Maximum de 8 cartes par case')
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });
    // Launch at each inPlanning update
    useEffect(() => {
        // sortPlanning()
        let copyPlanning = [...savePlanning]
        copyPlanning[row][col] = [...inPlanning]
        setSavePlanning(copyPlanning)
        setInplanning(sortPlanning(inPlanning));
    }, [inPlanning]);
    

    const sortPlanning = (planning) => {
        function compare(a, b) {
            let time1;
            let time2;
            if (a.debut && b.debut) {
                time1 = parseFloat(a.debut.replace(':', '.').replace(/[^\d.-]/g, ''));
                time2 = parseFloat(b.debut.replace(':', '.').replace(/[^\d.-]/g, ''));
            }
            if (time1 < time2) return -1;
            if (time1 > time2) return 1;
            return 0;
        }
        let sortedInPlanning = planning.sort(compare)
        return sortedInPlanning
    }


    const fusionPreviousCell = (e, row, col) => {
        if (inPlanning.length === 0) {
            if (props.i > 0) {
                let newCols = [...props.columns];
                newCols[props.i - 1] += props.colSize;
                console.log('newcols', newCols);
                console.log("i", props.i)
                newCols.splice(props.i, 1)
                console.log('newcolsAfter', newCols);
                props.setColumns(newCols);
            }
            return;
        }
    }

    const splitCell = () => {
        const sumCol = [...props.columns].reduce((partialSum, a) => partialSum + a, 0);
        console.log("sumcol : ", sumCol);
        console.log("days : ", days.length);
        if (props.colSize > 1 && sumCol <= days.length) {
            let newCols = [...props.columns];
            newCols[props.i] -= 1;
            newCols.push(1);
            console.log('newcolsAfter', newCols);
            props.setColumns(newCols);
            return;
        }

    }

    return (
        <td ref={dropRef} colSpan={props.colSize} id={props.i} row={props.row} col={props.i}  >
   
  
       
            <div className='key'>
            {inPlanning.length === 0 && props.i > 0 ? 
                (
                    <Button className='btn-fusion' onClick={(e => fusionPreviousCell())}>Fusionner avec la cellule précédente</Button>
                ) : (<></>)
                }
                {inPlanning.length === 0 && props.colSize  > 1 && props.columns.length < days.length ? 
                (
                    <Button className='btn-fusion' onClick={(e => splitCell())}>Découper cette cellule</Button>
                ) : (<></>)
                }
        
                {planningType !== "Adultes" ? (
                    <>
                        {inPlanning.map(item => <KidActivity row={props.row} className='itemInPlanning' key={item.id} data={item} type={'activityIn'} inPlanning={inPlanning} setInplanning={setInplanning} sortPlanning={sortPlanning} />)}
                        {isOver && <div>Lacher ICI</div>}
                    </>) :
                    (<>
                        {inPlanning.map(item => <SingleActivity row={props.row} className='itemInPlanning' key={item.id} data={item} type={'activityIn'} inPlanning={inPlanning} setInplanning={setInplanning} sortPlanning={sortPlanning} />)}
                        {isOver && <div>Lacher ICI</div>}
                    </>)
                }
            </div>
        </td>
    );
}
