export const daysFrEn = {
    lundi: "Monday",
    mardi: "Tuesday",
    mercredi: "Wednesday",
    jeudi: "Thursday",
    vendredi: "Friday",
    samedi: "Saturday",
    dimanche: "Sunday",
    matin: "Morning",
    'après-midi': "afternoon",
    'soirée': "evening"
}
export const daysFrEs = {
    lundi: "Lunes",
    mardi: "Martes",
    mercredi: "Miercoles",
    jeudi: "Jueves",
    vendredi: "Viernes",
    samedi: "Sabado",
    dimanche: "Domingo",
    matin: "Mañana",
    'après-midi': "Tarde",
    'soirée': "Anochecer"
}
export const daysFrIt = {
    lundi: "Lunedi",
    mardi: "Martedì",
    mercredi: "Mercoledì",
    jeudi: "Giovedì",
    vendredi: "Venerdì",
    samedi: "Sabato",
    dimanche: "Domenica",
    matin: "Mattina",
    'après-midi': "Pomeriggio",
    'soirée': "Serata"
}