import { React, useEffect, useState } from "react";
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Help from '../../assets/img/help.svg';
import "./help_button.scss";

export const HelpBtn = (props) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <ButtonUnstyled className='help-svg' onClick={handleOpen}><img src={Help}/></ButtonUnstyled>
            <Modal open={open} onClose={handleClose}>
                <Box className='ModalBox-video' component="div">
                    <embed type="video/webm"
                    src="https://sandakeys.fr/wp-content/uploads/2022/05/tuto_concepteur_planning.mp4"
                    />
                </Box>
            </Modal>
        </>
    )
}