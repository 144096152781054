import React from "react";
import "./search-box.scss";
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';

export const SearchBox = props => {
  return (
    <div className="search-group">
      <Input
        className="search-input"
        onChange={props.handleChange}
        value={props.value}
        placeholder={props.placeholder}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </div>

  );
};
