import { React, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import './popUp.scss'
import { CenterFocusStrong } from "@mui/icons-material";


export const PopUp = (props) => {

    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(!open);
        localStorage.setItem('newVersion', 'True')
    }
    const PATCH_NOTE = [
        'Les plannings Kids, Juniors et Teens sont maintenant disponibles, ces derniers ne possèdent qu\'une carte libre éditable.',
        `Vous pouvez modifier la tranche d'age d'un planning kids, teens et junior en cliquant directement sur le texte`,
        "Les cellules du planning peuvent maintenant être fusionnées, dans le cas ou les activités sont les mêmes plusieurs jours d'affilés.",
        `La ligne des soirées peut désormais être retirée`
    ]
    return (
        <>
            <div>
                <Modal open={open}>
                    <Box className='PopUpBox' >
                        <div className="box-content-modify">
                            <h3 className="pop-Up-title" style={{ textAlign: 'center' }}>Notes de mise à jour<br /> Version {props.appVersion}</h3>
                            <div className="col1">

                                <ul className="PatchList">
                                    {PATCH_NOTE.map((object, i) => <li key={i}>{object}</li>)}
                                </ul>
                            </div>
                            <div className="btn-group">
                                <button className="validation-btn" onClick={handleClose}>Ok</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}