import { React, useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import Modal from '@mui/material/Modal';
// import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import _uniqueId from 'lodash/uniqueId';
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select"
import domtoimage from 'dom-to-image';
import { HelpBtn } from "../help_button/help_button";
import './CreateActivity.scss';
import { PlanningContext } from "../../App";

export function CreateActivity(props) {

    const
        {
            camping,
            traduction,
            days,
            savePlanning,
            adultsActivities, setAdultsActivities,
            kidsActivities, setKidsActivities,
            planningType
        } = useContext(PlanningContext);

    const [open, setOpen] = useState(false);

    const [titre, setTitre] = useState("");
    const [heure, setHeure] = useState("");
    const [trad, setTrad] = useState("");
    const [duree, setDuree] = useState("");
    const [rdv, setRdv] = useState("");
    const [icon, setIcon] = useState("");
    const [type, setType] = useState(true);
    const [description, setDescription] = useState("");
    const [categoryValue, setCategoryValue] = useState("#FFFFFF00");

    const uniqueId = _uniqueId();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (planningType !== "Adultes") {
            setType(false);
        }
    }, [])


    function importAll(r) {
        let images = [];
        r.keys().map((item, index) => { images[index] = r(item); });
        console.log(images)
        return images;
    }
    const images = importAll(require.context('../../assets/img/icons', false, /\.(png|jpe?g|svg|PNG)$/));

    const addActivity = () => {
        if (type && (!titre || !heure)) {
            alert('titre/heure obligatoire')
            return;
        }
        if (titre.length > 25) {
            alert("Maximum de 25 caractères par nom d'activité");
            return;
        }

        if (planningType === "Adultes") {
            let alreadyEmptyCard = (adultsActivities.filter(e => !e.type))
            if (alreadyEmptyCard.length > 0 && !type) {
                alert('Une seule carte vide possible')
                return;
            }
            let toAdd = {
                id: uniqueId,
                titre: titre,
                traduction: trad,
                lieu: rdv,
                debut: heure,
                duree: duree,
                type: type,
                description: description,
                icon: icon,
                category: categoryValue
            }
            setAdultsActivities(adultsActivities => [...adultsActivities, toAdd]);
        }
        else {
            let toAdd = {
                id: uniqueId,
                titre: titre,
                traduction: trad,
                lieu: null,
                debut: heure,
                duree: null,
                type: type,
                description: description,
                icon: icon,
                category: categoryValue
            }
            setKidsActivities(kidsActivities => [...kidsActivities, toAdd]);
        }

        return handleClose();


    }
    const exportPlanning = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify({ adultsActivities, kidsActivities, camping, traduction, days, savePlanning, planningType, ...props })
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "Mon planning-" + moment(props.begin).format('D') + "-" + moment(props.end).format('D-MMMM') + ".json";
        link.click();
    }

    function filter(node) {
        return (node.className !== 'rsw-toolbar' && node.className !== 'qrcode-span');
    }

    const PlanningToImg = (e) => {
        const btn = document.getElementById('export_btn');
        const dom = document.getElementById('all-planning');
        const whiteBanner = document.getElementsByClassName('rsw-ce')[0];
        if (whiteBanner.textContent.length === 0) {
            document.getElementsByClassName("Planning-editor")[0].style.display = 'none';
        }
        const fusionCells = document.querySelectorAll('.btn-fusion');
        fusionCells.forEach(element => element.style.display = "none");
        btn.classList.add("loading");
        domtoimage.toJpeg(dom, {
            quality: 1,
            bgcolor: '#fff',
            filter: filter,
            style: {
                'max-width': 'none',
                'overflow': 'visible',
                'margin': '0',
                'transform': 'scale(1)'
            }
        })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'planning.jpeg';
                link.href = dataUrl;
                link.click();
                btn.classList.remove("loading");
                document.getElementsByClassName("Planning-editor")[0].style.display = 'flex';
                fusionCells.forEach(element => element.style.display = "block");
            })
    }
    const returnConfig = () => {
        return window.location = '/';
    }

    return (
        <div className="activity-creation">
            <div className="helpers-bar">
                <ButtonUnstyled className="return-btn" onClick={returnConfig} >Retour</ButtonUnstyled>
                <HelpBtn />
            </div>
            <div className="btn-bar">
                <ButtonUnstyled className="add-activity-btn" onClick={handleOpen} >Créer une animation</ButtonUnstyled>
                <ButtonUnstyled className="save-btn" onClick={exportPlanning}>Sauvegarder ton planning</ButtonUnstyled>
                <ButtonUnstyled className="export-btn" id='export_btn' onClick={PlanningToImg}>Exporter</ButtonUnstyled>
            </div>
            <Modal open={open} onClose={handleClose}>
                <Box className='ModalBox' component="form">
                    <div className="box-content">
                        <h3 className="create-activity-title">Créer une animation</h3>
                        {/* <FormControlLabel className='isActivity' label="Activité" control={<Switch checked={type} name="isActivity" onChange={changeType} color="warning" />} /> */}
                        {type ? (
                            <>
                                <div className="col2">
                                    <TextField id="titleInput" className="singleInput" label="Nom de l'animation" variant="outlined" onChange={(e) => setTitre(e.target.value)} type='text' />
                                    <TextField id="rdvInput" className="singleInput" label="Lieu de l'animation" variant="outlined" onChange={(e) => setRdv(e.target.value)} type='text' />
                                </div>
                                <div className="col2">
                                    <TextField id="heureInput" className="singleInput" label='Heure' variant="outlined" InputLabelProps={{ shrink: true }} value={heure} onChange={(e) => setHeure(e.target.value)} type='time' />
                                    <TextField id="dureeInput" className="singleInput" label="Durée" variant="outlined" onChange={(e) => setDuree(e.target.value)} type='number' InputProps={{ endAdornment: <InputAdornment position="end">min</InputAdornment> }} />
                                </div>
                                <div className="col3">

                                    <FormControl fullWidth>
                                        <InputLabel id="select-category">Catégorie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={categoryValue}
                                            label="Type de carte"
                                            onChange={(e) => setCategoryValue(e.target.value)}
                                        >
                                            <MenuItem value={'#e34b36'}>Activités sportives</MenuItem>
                                            <MenuItem value={'#ec60c5'}>Activités ludiques</MenuItem>
                                            <MenuItem value={'#009ddd'}>Activités aquatiques</MenuItem>
                                            <MenuItem value={'#18a975'}>Activités découvertes</MenuItem>
                                            <MenuItem value={'#f47d21'}>Activités fitness</MenuItem>
                                            <MenuItem value={'#9747ff'}>Evènementiel</MenuItem>
                                            <MenuItem value={'#FFFFFF00'}>Autre</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <h3 className="create-activity-title-two">Choix de l'icone</h3>
                                <div className="col2 icon-choicer">
                                    {images.map((e, i) => (
                                        <label key={i} className='icon-element'>
                                            <input type="radio" name="icon" value={e} onChange={(e) => setIcon(e.target.value)} />
                                            <img className="icon-in-choicer" src={e} />
                                        </label>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col2">
                                    <TextField id="descriptionInput" multiline rows={3} className="singleInput textArea" label="Description de la carte libre" variant="outlined" onChange={(e) => setDescription(e.target.value)} type='text' />
                                    
                                </div>
                                <div className="col2"> 
                                 <TextField id="heureInput" className="singleInput" label='Position horaire' variant="outlined" InputLabelProps={{ shrink: true }} value={heure} onChange={(e) => setHeure(e.target.value)} type='time' />
                                </div>
                                
                            </>
                        )}
                        <div className="group-btn">
                            <ButtonUnstyled className="annulation-btn" onClick={handleClose}>Annuler</ButtonUnstyled>
                            <ButtonUnstyled className="validation-btn" onClick={addActivity}>Valider</ButtonUnstyled>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}


