import { React, useState, useContext } from "react"
import Check from '../../../assets/img/check.svg'
import { PlanningContext } from "../../../App";


export function FileUploader(props) {

  const [imported, setImported] = useState(false)
  const
  {
      setCamping,
      setTraduction,
      setDays,
      setSavePlanning,
      setPlanningType,
      setPeriodStart,
      setAdultsActivities,
      setKidsActivities
  } = useContext(PlanningContext);
  // allprops.activities  allprops.allDates, allprops.camping, allprops.days, allprops.periodStart, allprops.save && all setters
  const onChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      let toObject = JSON.parse(e.target.result)
      if (toObject.hasOwnProperty('activities')) {
        setAdultsActivities(toObject.activities)
      }
      else {
        setAdultsActivities(toObject.adultsActivities); //ok
        setKidsActivities(toObject.kidsActivities)
      }
      setSavePlanning(toObject.savePlanning); // ok
      setDays(toObject.days); // ok
      setPeriodStart(toObject.begin); //ok
      setCamping(toObject.camping);
      if (!toObject.hasOwnProperty('planningType')) {
        setPlanningType("Adultes");
      }
      else {
        setPlanningType(toObject.planningType);
      }
   
      setTraduction(toObject.traduction)
      setImported(true)
    }
  }
  return (
    <>
      {imported ? (<img src={Check} width="25px" />) : (<></>)}
      <label className="custom-file-upload">
        <input type="file" onChange={onChange} />
        <span>Importer un planning</span>
      </label>
    </>
  );


}