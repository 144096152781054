import React from "react";
import './footer.scss'
export const Footer = (props) => {


    return (
        <div className="footer">
            V.{props.appVersion}
        </div>
    )
}
