import { React, useEffect, useState, useContext } from "react";
import './configurateur.scss';
import moment from "moment";
import 'moment/locale/fr'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { HelpBtn } from "../../components/help_button/help_button";
import { FileUploader } from "../../components/create_activity/fileUploader/fileUploader";
import { Footer } from "../../components/footer/footer";
import { PopUp } from "../../components/popUp/popUp";
import { PlanningContext } from "../../App"

function Configurateur(props) {

    const [newVersion, setNewVersion] = useState(true)

    const
        {
            setCamping,
            periodStart, setPeriodStart,
            allDates, setAllDates,
            traduction, setTraduction,
            days, setDays,
            savePlanning, setSavePlanning,
            planningType, setPlanningType,
            lines, setLines,
            activities, setActivities,
        } = useContext(PlanningContext);

    //Appel de la fonction une fois le chargement de la page ok
    useEffect(() => {
        DateParser();
        localStorage.getItem('newVersion') ? setNewVersion(false) : (<></>)
        console.log(lines);

    }, []);


    const onTraductionChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setTraduction(value);
    }
    const onCampingChange = (event) => {
        const target = event.target;
        const value = target.value;
        setCamping(value);
    }
    const onPlanningTypeChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setPlanningType(value);
    }


    // Défini les prochains jours du planning
    const DateParser = (event) => {

        let tempDates = []
        if (event) {
            setPeriodStart(event.target.value);
            tempDates.push(event.target.value);
            for (let index = 1; index < 7; index++) {
                tempDates.push(moment(new Date(event.target.value)).add(index, 'd').format('YYYY-MM-DD'));
            }
        }
        else {
            tempDates.push(
                periodStart);
            for (let index = 1; index < 7; index++) {
                tempDates.push(moment(new Date(periodStart)).add(index, 'd').format('YYYY-MM-DD'));
            }
        }
        setAllDates([...tempDates]);
        setDays([...tempDates]);
        defineSavePlanning(tempDates);

    }
    const defineSavePlanning = (days) => {
        let tempSavePLanning = []
        const linesInStr = ['Matin', 'Après-Midi', 'Soirée'];
        if (savePlanning.length === 0) {

            for (let row of Object.keys(linesInStr)) {
                tempSavePLanning[row] = []
                for (let [col, val] of Object.keys(days)) {
                    tempSavePLanning[row][col] = []
                }
            }
            return setSavePlanning(tempSavePLanning)
        }
        return
    }

    const updateDays = (event) => {
        event.preventDefault();
        if (days.includes(event.target.name)) {
            let daysKept = [...days].filter(
                function (date) {
                    return date !== event.target.name;
                }
            );
            setDays(daysKept);

        }
        else {
            setDays([...days, event.target.name]);
        }


    }
    const returnHome = () => {
        return window.location = '/';
    }

    const updateLines = (event) => {

        // comparison function


        // event.preventDefault();
        if (lines.includes(event.target.name)) {
            let linesKept = lines.filter(
                function (line) {
                    return line !== event.target.name;
                }
            );

            return setLines(linesKept);
        }
        else {
            let futureLines = [...lines, event.target.name];
            return setLines(futureLines);
        }


    }

    return (
        <>
            {newVersion ? (<PopUp appVersion={props.appVersion} />) : (<></>)}
            <div className="container-config">
                <ButtonUnstyled className="return-btn-config" onClick={returnHome} >Retour</ButtonUnstyled>
                <div className='btn-group'>
                    <FileUploader className='Upload-btn' />
                    <HelpBtn />
                </div>
                <div className="container-splitter">

                    <div className="left-side">
                        <div className="group-form camping">
                            <h3>Votre camping</h3>
                            <input type="text" className='textInput camping-input' name='camping' placeholder="Camping Sandaya Domaine du Verdon" onChange={onCampingChange}></input>
                        </div>
                        <div className="group-form period">
                            <h3>Début du planning</h3>
                            <input type="date" value={periodStart} name='' className='dateInput date-input' onChange={DateParser} ></input>
                        </div>
                        <div className="group-form traduction">
                            <h3>Type de planning</h3>
                            <div className="radio-group-traduction">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={planningType}
                                        onChange={onPlanningTypeChange}
                                    >
                                        <FormControlLabel
                                            label="Kids"
                                            size="small"
                                            control={<Radio color="warning" />}
                                            color="warning"
                                            value="Kids"
                                            name='planningType'

                                        />
                                        <FormControlLabel
                                            label='Juniors'
                                            size="small"
                                            control={<Radio color="warning" />}
                                            value="Juniors"
                                            name='planningType'

                                        />
                                        <FormControlLabel
                                            label='Teens'
                                            size="small"
                                            control={<Radio color="warning" />}
                                            value="Teens"
                                            name='planningType'

                                        />
                                        <FormControlLabel
                                            label='Adultes'
                                            size="small"
                                            control={<Radio color="warning" />}
                                            value="Adultes"
                                            name='planningType'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="group-form traduction">
                        <h3>Affichage de la soirée</h3>
                            <div className="radio-group-traduction">
                                <FormControl value={lines}>

                                    <FormControlLabel labelPlacement="start" value={"Soirée"} label={"Soirée"} control={<Switch checked={lines.includes("Soirée")} name={"Soirée"} onChange={updateLines} color="warning" />} />
                                </FormControl>
                            </div>
                        </div>
                        <div className="group-form traduction">
                            <h3>Traduction</h3>
                            <div className="radio-group-traduction">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={traduction}
                                        onChange={onTraductionChange}
                                    >
                                        <FormControlLabel
                                            label="Anglais"
                                            size="small"
                                            control={<Radio color="warning" />}
                                            color="warning"
                                            value="Anglais"
                                            name='planningTraduction'

                                        />
                                        <FormControlLabel
                                            label='Espagnol'
                                            size="small"
                                            control={<Radio color="warning" />}
                                            value="Espagnol"
                                            name='planningTraduction'

                                        />
                                        <FormControlLabel
                                            label='Italien'
                                            size="small"
                                            control={<Radio color="warning" />}
                                            value="Italien"
                                            name='planningTraduction'

                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <h3 className="right-side-title">Votre semaine</h3>
                        <FormGroup value={days}>
                            {allDates.map((el) => <FormControlLabel labelPlacement="start" value={el} label={moment(new Date(el)).format('dddd DD MMMM YYYY')} key={JSON.stringify(el)} control={<Switch checked={days.includes(el)} name={el} onChange={updateDays} color="warning" />} />)}
                        </FormGroup>
                        <div className="btn-continue-group">
                            <Link className="btn-continue" to={"/planning"}>Continuer</Link>
                        </div>

                    </div>
                </div>
                <Footer appVersion={props.appVersion} />
            </div>
        </>
    )
}

export default Configurateur;