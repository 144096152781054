import React, { useContext } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { PlanningCase } from './PlanningCase';
import { PlanningContext } from '../../../App';
const PlanningConceptorLines = (props) => {

    const
        { planningType } = useContext(PlanningContext);

    const [columns, setColumns] = useState(Array(props.columns).fill(1));
    const [hourRange, setHourRange] = useState('00h-00h');

    useEffect(() => {
        switch (props.linesInStr.toLowerCase()) {
            case "matin":
                setHourRange("10h-12h");
                break;

            case "après-midi":
                setHourRange("15h-17h");
                break;
            case "soirée":
                setHourRange("17h-21h");
                break;

            default:
                setHourRange("00h-00h");

        }
    }, [])

    return (
        <>
            <tr className={`row-${props.linesInStr}`}>
                <td className={`hour row-${props.linesInStr}`} rowSpan="2" style={{ position: 'relative' }}>
                    <span>{props.linesInStr}<br /></span>
                    <span className='trad-text'>
                        {props.traduction[props.linesInStr.toLowerCase()]}
                    </span>
                    {planningType !== "Adultes" ?
                        (<div className="hour-range"><input type="text" value={hourRange} onChange={(e) => setHourRange(e.target.value)} autoFocus></input></div>) :
                        (<></>)}
                </td>
            </tr>
            <tr className={`row-${props.linesInStr}`}>
                {[...columns].map((e, i) => <PlanningCase columns={columns} colSize={e} setColumns={setColumns} activityInThisCase={[...props.activitiesInLine[i]]} key={i} i={i} row={props.row}></PlanningCase>)}
            </tr>
        </>
    )
}
export default PlanningConceptorLines;