import './App.scss';
import { createContext, React, useState } from "react";
import moment from 'moment';
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import Configurateur from './pages/configurateur/configurateur.jsx';
import Planning from './pages/planning/planning.jsx';

export const PlanningContext = createContext()

function App() {

  const [camping, setCamping] = useState("");
  const [periodStart, setPeriodStart] = useState(moment().format('YYYY-MM-DD'));
  const [allDates, setAllDates] = useState([]);
  const [planningType, setPlanningType] = useState("Adultes");
  const [traduction, setTraduction] = useState("Anglais");
  const [days, setDays] = useState("");
  const [savePlanning, setSavePlanning] = useState([]);
  const [adultsActivities, setAdultsActivities] = useState([]);
  const [kidsActivities, setKidsActivities] = useState([]);
  const [lines, setLines] = useState(['Matin', 'Après-Midi', 'Soirée'])


  const APP_VERSION = 1.9



  return (
    <PlanningContext.Provider value={
      {
        camping, setCamping,
        periodStart, setPeriodStart,
        allDates, setAllDates,
        planningType, setPlanningType,
        traduction, setTraduction,
        days, setDays,
        savePlanning, setSavePlanning,
        adultsActivities, setAdultsActivities,
        kidsActivities, setKidsActivities,
        lines, setLines
      }}>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Configurateur
                appVersion={APP_VERSION}
              />}>
          </Route>
          <Route
            path="/planning"
            element={
              <Planning
                appVersion={APP_VERSION}
              />}>
          </Route>
        </Routes>
      </HashRouter>
    </PlanningContext.Provider>
  );
}

export default App;
