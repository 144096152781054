import { React, useState, useEffect, useContext, useRef} from "react";
import './kid_activity.scss';
import { useDrag } from "react-dnd";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { DefaultEditor } from 'react-simple-wysiwyg';

import { PlanningContext } from "../../../App"
import { Input } from "@mui/material";

import koolaya from "../../../assets/img/koolaya/Koolaya_01.png";
import koolayaMusic from "../../../assets/img/koolaya/Koolaya_20.png";
import koolayaPool from "../../../assets/img/koolaya/Koolaya_03.png";
import koolayaRun from "../../../assets/img/koolaya/Koolaya_06.png";

const KidActivity = (props) => {

    const
        {
            planningType
        } = useContext(PlanningContext);

    const [id, setId] = useState(props.data.id);
    const [titre, setTitre] = useState(props.data.titre);
    const [trad, setTrad] = useState(props.data.trad);
    const [icon, setIcon] = useState(props.data.icon);
    const [debut, setDebut] = useState(props.data.debut ? props.data.debut : "00:00");
    const [duree, setDuree] = useState(props.data.duree);
    const [type, setType] = useState("activity");
    const [isActivity, setIsActivity] = useState(props.data.type)
    const [description, setDescription] = useState(props.data.description)
    const [backgroundEmptyCard, setBackgroundEmptyCard] = useState('#ffffff')
    const [colorEmptyCard, setColorEmptyCard] = useState('#000000')
    const [fontSize, setFontSize] = useState(14);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (props.type) {
            setType(props.type);
        }

    }, [props.data.type, open])

    const isFirstrender = useRef(true); // useRef to prevent the first render

    useEffect(() => {
        if (isFirstrender.current) {
            isFirstrender.current = false;
          return; // return early if initial render
        }
      
        const updatedId = id; // Store the id state variable in a separate variable
      
        props.setInplanning((prevInPlanning) => {
          return prevInPlanning.map((element) => {
            if (element.id === updatedId) {
              return { ...element, icon: icon, description : description};
            }
            return element;
          });
        });
      }, [icon, description]);


    const changeHeure = (e) => {
        setDebut(e.target.value);
        let temp = [...props.inPlanning]
        let future = [];
        temp.map((element) => {
            element.id === id ? (element = { ...element, ...{ debut: e.target.value } }) : (element = element);
            future.push(element);
        })
        props.setInplanning(future);
    }
    const onChangeFile = async (e) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0], "UTF-8");
        fileReader.onload = async (e) => {
        setIcon(e.target.result)
        }
    }

    const changeDescription = (e) => {
        setDescription(e.target.value);
    }
    const changeFontSize = (e) => {
        setFontSize(e.target.value)
    }

    const ChangeEmptyCardType = (e) => {
        let currentColor = e.target.value
        setBackgroundEmptyCard(currentColor)
        let colorsRelative = {
            '#ffffff': '#000000',
            '#AB9967': '#ffffff',
            '#ab9967': '#ffffff'
        }
        setColorEmptyCard(colorsRelative[currentColor])
    }



    const deleteActivity = (e) => {
        let id = e.target.attributes.idelement.value
        let nextPlanning = []
        props.inPlanning.forEach((el) => {
            if (el.id === id) {
                return
            }
            nextPlanning.push(el)
        })
        props.setInplanning([...nextPlanning])
        handleClose();
    }

    const [{ isDragging }, dragRef] = useDrag({
        type: type,
        item: { id: id, titre: titre, trad: trad, debut: debut, duree: duree, type: isActivity, description: description, icon: icon },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: type === 'activity' ? true : false
    })

    return (
        <>
            <Modal open={open} onClose={handleClose}>

                <Box className='ModalBox' component="form">
                    <div className="box-content-modify">
                        <h3 className="create-activity-title">Modifier une carte libre</h3>
                        <div className="col1">
                            <TextField id="heureInput" className="singleInput" label="Position horaire" variant="outlined" onChange={changeHeure} type='time' value={debut} />
                            <div style={{ border: "1px solid #8080807a", borderRadius: "4px", padding: "5px", maxWidth: "95%" }}>
                                <DefaultEditor value={description} onChange={changeDescription} />
                            </div>
                            <TextField
                                id="fontSizeInput"
                                className="singleInput"
                                label="Taille du texte"
                                variant="outlined"
                                type='number'
                                onChange={changeFontSize}
                                value={fontSize}
                                InputProps={{
                                    inputProps: {
                                        max: 30, min: 10
                                    }
                                }}
                            />

                            {props.row !== 2 ? (<FormControl fullWidth>
                                <InputLabel id="select-background">Type de carte</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={backgroundEmptyCard}
                                    label="Type de carte"
                                    onChange={ChangeEmptyCardType}
                                >
                                    <MenuItem value={'#ffffff'}>Fond blanc</MenuItem>
                                    <MenuItem value={'#AB9967'}>Fond Doré</MenuItem>
                                    <MenuItem value={koolaya}>Koolaya Hello</MenuItem>
                                    <MenuItem value={koolayaMusic}>Koolaya Musique</MenuItem>
                                    <MenuItem value={koolayaPool}>Koolaya Piscine</MenuItem>
                                    <MenuItem value={koolayaRun}>Koolaya Course</MenuItem>

                                </Select>
                            </FormControl>) : (<></>)}



                            <InputLabel id="select-img">Image</InputLabel>
                            <label className="custom-file-upload-2">
                                <input type="file" onChange={onChangeFile} accept=".jpg,.png,.svg,.jpeg" />
                                <span className='qrcode-span' >Ajouter une image</span>
                            </label>

                        </div>
                        <div className="btn-group">
                            <ButtonUnstyled className="delete-btn" onClick={deleteActivity} idelement={id}>Supprimer</ButtonUnstyled>
                            <ButtonUnstyled className="validation-btn" onClick={handleClose}>Valider</ButtonUnstyled>
                        </div>
                    </div>
                </Box>
            </Modal>
            <div style={backgroundEmptyCard.length <= 7 ? { background: backgroundEmptyCard } : { background: `url(${backgroundEmptyCard})` }} className={`kids-card activity-${id}`} ref={dragRef} onClick={type === 'activityIn' ? handleOpen : handleClose}>
                <div className="activity-time-kid">
                    <div className="activity-head-one-kid">
                        {debut !== "00:00" ? (<div className="activity-time-debut-kid">{debut}</div>) : (<></>)}

                        {duree ? (<div className="activity-time-duree-kid">{duree}"</div>) : (<></>)}
                    </div>

                </div>
                <div className="emptyCard-title">
                    <span style={{ color: colorEmptyCard, fontSize: `${fontSize}px` }} dangerouslySetInnerHTML={{ __html: description }}></span>
                    {icon ? (<img className='img-card' src={icon} alt='QrCode' />) : (<></>)}
                </div>
            </div>
        </>
    )
}
export default KidActivity;
