import { React, Component, useState, useEffect, useContext } from "react";
import SingleActivity from "./single_activity/single_activity";
import { SearchBox } from "./search-box/search-box";

import '../../App.scss';
import './planning_activity.scss';

import { PlanningContext } from "../../App"
import KidActivity from "./kid_activity/kid_activity";




const Activities = (props) => {

    const
    {
        adultsActivities,
        kidsActivities,
        planningType
    } = useContext(PlanningContext);


    const [searchField, setSearchField] = useState("")

    const [filteredActivities, setFilteredActivities] = useState(props.data.filter(activity =>
        activity.titre.toLowerCase().includes(searchField.toLowerCase())
    ))
    // When the user scrolls the page, execute myFunction
    useEffect(() => {
        window.onscroll = function () { myFunction() };
        var header = document.getElementById("Activities-container");
        // Get the offset position of the navbar
        var sticky = header.offsetTop;
        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
        return
    }, []);



    useEffect(() => {
        setFilteredActivities(props.data.filter(activity =>
            activity.titre.toLowerCase().includes(searchField.toLowerCase())
        ))
    }, [props.data, searchField]);

    const handleChange = event => {
        setSearchField(event.target.value);
    };
    const activityDiv = document.getElementsByClassName('Activities-container')[0];

    function scrollLeft() {
        activityDiv.scrollLeft -= 222;

    }

    function scrollRight() {
        activityDiv.scrollLeft += 222;
    }

    return (
        <>
            {planningType !== "Adultes" ? (
                < KidsRenderActivities handleChange={handleChange} scrollLeft={scrollLeft} scrollRight={scrollRight} filteredActivities={filteredActivities} kidsActivities={kidsActivities} />
            ) : (
                < AdultesRenderActivites handleChange={handleChange} scrollLeft={scrollLeft} scrollRight={scrollRight} filteredActivities={filteredActivities} adultsActivities={adultsActivities} />
            )
            }
        </>


    )
}

const AdultesRenderActivites = (props) => {
    return (
        <div id='Activities-container'>
            <SearchBox
                placeholder="Trouve ton animation"
                handleChange={props.handleChange}
            />
            <div className="sliderActivity">
                <span className="arrow-slider" id="slideLeft" onClick={props.scrollLeft}></span>
                <div className="Activities-container">
                    {props.adultsActivities.map((el, i) => (
                        props.filteredActivities.includes(el) ? (
                            <SingleActivity
                                data={el}
                                key={i}
                            />) : null
                    ))}
                </div>
                <span className="arrow-slider" onClick={props.scrollRight} id="slideRight"></span>
            </div>
        </div>
    )
}
const KidsRenderActivities = (props) => {

    return (
        <div id='Activities-container'>
        <SearchBox
            placeholder="Trouve ton animation"
            handleChange={props.handleChange}
        />
        <div className="sliderActivity">
            <span className="arrow-slider" id="slideLeft" onClick={props.scrollLeft}></span>
            <div className="Activities-container-kids">
                {props.kidsActivities.map((el, i) => (
                    props.filteredActivities.includes(el) ? (
                        <SingleActivity
                            data={el}
                            key={i}
                        />) : null
                ))}
            </div>
            <span className="arrow-slider" onClick={props.scrollRight} id="slideRight"></span>
        </div>
    </div>
    )
}

export default Activities;
