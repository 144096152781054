import { React, useState, useEffect, useContext } from "react";
import './planning.scss';
import moment from "moment";
import PlanningConceptor from '../../components/planning_conceptor/planning_conceptor';
import Activities from '../../components/planning_activity/planning_activity.jsx';
import { CreateActivity } from "../../components/create_activity/CreateActivity";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { DefaultAdultsActivities, DefaultKidsActivities } from "../../assets/data/activities";
import { Wysiwyg } from "../../components/wysiwyg/wysiwyg";
import { isMobile } from "react-device-detect";
import { Footer } from "../../components/footer/footer";
import { PlanningContext } from "../../App"

// Assets
import logoSandaya from '../../assets/img/logo-white-sandaya.svg';

function Planning(props) {

    const
        {
            camping,
            traduction,
            days,
            adultsActivities, setAdultsActivities,
            kidsActivities, setKidsActivities,
            planningType,
            lines
        } = useContext(PlanningContext);
    // Si des props essentiels sont manquants, retour au configurateur

    // Noms des lignes
    // const linesInStr = ['Matin', 'Après-Midi', 'Soirée'];

    useEffect( () => {
        console.log(lines);
    }, [])

    if (!days) {
        window.location = '/';
    }

    //Text editables
    const [ageRange, setAgeRange] = useState("5 - 17 ans");

    // Ajoute les activités au localStorage à la fermeture de la page
    window.addEventListener("beforeunload", function (e) {
        if (adultsActivities.length > 0) {
            localStorage.setItem('adultsActivities', JSON.stringify(adultsActivities))
        }
        if (kidsActivities.length > 0) {
            localStorage.setItem('kidsActivities', JSON.stringify(kidsActivities))
        }
    });

    useEffect(() => {

        if (localStorage.getItem('adultsActivities') != null) {
            setAdultsActivities(JSON.parse(localStorage.getItem('adultsActivities')))
        }
        else if (DefaultAdultsActivities.length > 0 && adultsActivities.length === 0) {
            setAdultsActivities(DefaultAdultsActivities);
        }

        if (localStorage.getItem('kidsActivities') != null) {
            setKidsActivities(JSON.parse(localStorage.getItem('kidsActivities')))
        }
        else if (DefaultKidsActivities.length > 0 && kidsActivities.length === 0) {
            setKidsActivities(DefaultKidsActivities);
        }
    }, []);

    const begin = days[0];
    const end = days[days.length - 1];
    // Rempli un tableau contenant les jours (pour les colonnes)
    const daysInStr = [];
    days.map(el => {
        return daysInStr.push(moment(el).format('dddd'));
    });

    const toLocale = {
        'Anglais': ['from', 'to'],
        'Italien': ['dal', 'al'],
        'Espagnol': ['del', 'al']
    }
    const months = {
        'Anglais': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        'Italien': ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        'Espagnol': ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    }
    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div className="parentContainer">
                <div className="container planning">
                    <div className="Activity-sidebar">
                        <div className="Activity-Container">
                            <CreateActivity begin={begin} end={end} />
                            <Activities data={planningType === "Adultes" ? adultsActivities : kidsActivities} />
                        </div>
                    </div>
                    <div className="planningContent" id='all-planning'>
                        <div className="planning-header">
                            <img src={logoSandaya} className={`logoSandaya ${planningType}-logo`} alt='logo Sandaya' />
                            <div className={`planning-header-content ${planningType}-background`}>
                                {planningType !== "Adultes" ?
                                    (<div className="age-range"><h2>{`${planningType} Club`}</h2><input type="text" value={ageRange} onChange={(e) => setAgeRange(e.target.value)} autoFocus></input></div>) :
                                    (<h2>Programme d'animations</h2>)}
                                <span className="campingName">{camping}</span>
                                <span className={`moment-planning ${planningType}-color`}>
                                    Du {moment(begin).format('DD')} au {moment(end).format('DD MMMM')}<br />
                                    {toLocale[traduction][0] + ' ' + moment(begin).format('DD') + ' ' + toLocale[traduction][1] + ' ' + moment(end).format('DD') + ' ' + months[traduction][parseInt(moment(end).locale(toLocale[traduction]).format('M')) - 1]}
                                </span>
                            </div>
                        </div>
                        <div>
                            <PlanningConceptor columns={days.length} daysInStr={daysInStr} lines={lines.length} linesInStr={lines} />
                        </div>
                        <div className="Planning-editor">
                            <Wysiwyg innerContent={'<div></div>'} />
                        </div>
                    </div>
                </div>
                <Footer appVersion={props.appVersion} />
            </div>
        </DndProvider>

    )
}
export default Planning;